<template>
  <div v-loading="loading" class="table-area history-area">
    <div class="table-top">
    <el-row>
      <el-col :xs="24" :sm="6" :md="6" :lg="6" :xl="6">
        <h4 class="heading heading-active">History</h4>
      </el-col>
      <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12">
         <!-- &nbsp;  -->
        <el-col v-if="getRoleSlug === 'superadmin'" :xs="24" :sm="12" :md="12" :lg="12" :xl="12">
          <div class="search-wrapper">
            <el-input
              placeholder="Search"
              v-model="listQuery.search"
              class="input-with-select"
              style="width:500px;"
            >
              <el-select
                slot="prepend"
                v-model="listQuery.search_type"
                placeholder="select"
                clearable
                filterable
                style="width: 150px"
                @change="handleSelectSearchType()"
              >
                <el-option
                  v-for="item in listAdmins"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id"
                />
              </el-select>
              <el-select
                slot="prepend"
                v-model="listQuery.search_type_client"
                placeholder="select"
                clearable
                filterable
                style="width: 150px"
                @change="handleSelectSearchType()"
              >
                <el-option
                  v-for="item in listDataDropdownClients"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id"
                />
              </el-select>
              <el-button
                slot="append"
                icon="el-icon-search"
                @click="sendChildFormData()"
              ></el-button>
            </el-input>
          </div>
        </el-col>
        <el-col v-if="getRoleSlug === 'admin' || getRoleSlug === 'broker'" :xs="24" :sm="12" :md="12" :lg="12" :xl="12">
          <div class="search-wrapper">
            <el-input
              placeholder="Search"
              v-model="listQuery.search"
              class="input-with-select"
              style="width:500px;"
            >
              <el-select
                slot="prepend"
                v-model="listQuery.search_type_client"
                placeholder="select"
                clearable
                filterable
                style="width: 150px"
                @change="handleSelectSearchTypeClient()"
              >
                <el-option
                  v-for="item in listDataDropdownClients"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id"
                />
              </el-select>
              <el-button
                slot="append"
                icon="el-icon-search"
                @click="sendChildFormData()"
              ></el-button>
            </el-input>
          </div>
        </el-col>
      </el-col>
      <el-col :xs="24" :sm="6" :md="6" :lg="6" :xl="6">
        <div class="btn-wrapper"></div>
      </el-col>
    </el-row>
    </div>
    <Table
      tableKey="closePositions"
      :tableConfig="config"
      :tableData="listData"
      :tablePagination="tablePagination"
      :tableQuery="listQuery"
      :tableActionShowVisibility="false"
      :showSummary="false"
      :tableActionVisibility="false"
      :tableLoadingStatus.sync="loading"
      @pagination="handlePagination()"
    />
  </div>
</template>

<script>
import Table from "@/components/Table/Table.vue";
import { fetchList } from "@/api/action-leager";
import { fetchAllAdminList } from "@/api/super-admin/admin";
// import { _ } from "vue-underscore";
import { mapGetters } from "vuex";
import getRoleSlug from "@/store/app.js";
import { fetchClientList } from "@/api/market-watch";


export default {
  name: "ListClosePositions",
  components: {
    Table,
  },
  data() {
    return {
      listAdmins: {},
      listDataDropdownClients: {},
      loading: false,
      search: "",
      listDataTemp: null,
      config: [
        {
          label: "Message",
          prop: "message",
          width: "",
          className: "blueFont",
        },
        {
          label: "Created At",
          prop: "created_at",
          width: "200",
          className: "blueFont",
        },
      ],
      listQuery: {
        page: 1,
        limit: 20,
        search: "",
        search_type: "",
        search_type_client: "",
        searchJoin: "or",
        orderBy: "created_at",
        sortedBy: "desc",
      },
      listData: {
        data: {
          item: [],
        },
      },
      tablePagination: {}
    };
  },
  computed: {
     ...mapGetters(["getRoleSlug"]),
  },
  created() {
    this.getList();
     if(getRoleSlug.state.roleSlug == 'superadmin') {
      this.getAdminList();
    }
    // else if(getRoleSlug.state.roleSlug == 'admin' || getRoleSlug.state.roleSlug == 'broker') {
    //   this.getClientList();
    // }
    this.getClientList();
  },
  methods: {
    // //////////////////
    // HANDLE SEARCH
    // //////////////////
    // handleSearch() {
    //   this.listData.data.item = this.listDataTemp;
    //   let s = this.search;
    //   s = s.toLowerCase();
    //   console.log(s);
    //   this.listData.data.item = _.filter(this.listData.data.item, function (d) {
    //     return d["message"] && d["message"].toLowerCase().includes(s);
    //   });
    // },
    handleSelectSearchType() {
      this.handleSearch()
      if(getRoleSlug.state.roleSlug == 'superadmin') {
        this.getClientList();
      }
    },
    handleSelectSearchTypeClient() {
      this.handleSearch()
    },
    handleSearch() {
      this.listQuery.page = 1;
      // this.listQuery.search_type = this.listQuery.search_type;
      this.getList();
    },
    // //////////////////
    // GET LIST DATA
    // //////////////////
    handlePagination() {
      this.getList();
    },

    getList() {
      this.loading = true;
      fetchList(this.listQuery).then((response) => {
        this.listData.data.item = response.data.data.data;
        this.tablePagination = response.data.data.meta;
        // this.listDataTemp = this.listData.data.item = response.data.data.data;
        this.loading = false;
      });
    },
    getAdminList() {
      this.loading = true;
      fetchAllAdminList().then((response) => {
        this.listAdmins  = response.data.data;
        console.log(this.listAdmins)
        this.loading = false;
      });
    },
    getClientList() {
      this.loading = true;
      fetchClientList(this.listQuery).then((response) => {
        console.log("point 1 ")
        this.listDataDropdownClients = response.data.data;
      });
    },
  },
};
</script>
